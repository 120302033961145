import {useTranslation} from 'react-i18next';
import {getToken} from '@/common-pages/login/login.service';
import {postReport, TReportType, TSourceType} from '@/services/global.service';
import globalStore from '@/services/global.state';
import {goTo, goBack, replace, navigationRef} from '@/utils';
import {homePage} from '@/config';

interface UseThirdPartyAuthProps {
  fromPage?: string;
  backPage?: string;
  sucessPage?: string;
  sucessPageParams?: any;
  userPhone?: string;
  OTPCode?: string;
}

export const useThirdPartyAuth = ({
  fromPage,
  backPage,
  sucessPage,
  sucessPageParams,
  userPhone = '',
  OTPCode = '',
}: UseThirdPartyAuthProps) => {
  const {i18n} = useTranslation();

  const handleThirdPartyAuth = async () => {
    globalStore.globalLoading.next(true);

    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');
    const state = urlParams.get('state');
    const isNewUser = urlParams.get('isNewUser');
    const result = urlParams.get('result');

    try {
      // 处理登录失败
      if (result === 'failed') {
        globalStore.globalLoading.next(false);
        setTimeout(() => {
          globalStore.globalWaringTotal(i18n.t('login.failed.message'));
        }, 100);
        return;
      }

      // 存储第三方登录参数
      if (source && state) {
        localStorage.setItem('thirdPartySource', source);
        localStorage.setItem('thirdPartyState', state);
        globalStore.setItem('thirdPartySource', source);
        globalStore.setItem('thirdPartyState', state);

        const callbackInfo = {
          fromPage,
          backPage,
          sucessPage,
          sucessPageParams,
        };
        localStorage.setItem('loginCallbackInfo', JSON.stringify(callbackInfo));
      }

      if (!source || !state) {
        globalStore.globalLoading.next(false);
        return;
      }

      // 处理新用户
      if (isNewUser === 'true') {
        globalStore.globalLoading.next(false);
        window.location.href =
          window.location.origin + '/bind-phone?source=login';
        return;
      }

      // 获取 token
      const res = await getToken('', userPhone, OTPCode);
      globalStore.globalLoading.next(false);

      if (res?.token) {
        globalStore.token = res.token;

        // 处理新用户注册上报
        if (res.isNewUser) {
          postReport({
            reportType: TReportType.REGISTER,
            sourceType:
              localStorage.getItem('from') === 'im'
                ? TSourceType.IM
                : TSourceType.CHANNEL,
          }).then(() => localStorage.removeItem('from'));
        }

        // 处理导航
        if (sucessPage) {
          replace(sucessPage, sucessPageParams);
        } else {
          if (fromPage === 'chat') {
            goTo(homePage, {screen: 'Chat'});
          } else if (
            !navigationRef.current ||
            navigationRef.current.getState().routes.length < 2
          ) {
            goTo(homePage, {screen: 'Home'});
          } else {
            goBack();
          }
        }
      }
    } catch (error) {
      globalStore.globalLoading.next(false);
      console.error('Auth error:', error);
    }
  };

  return {handleThirdPartyAuth};
};
